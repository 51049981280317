//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseBreadcrumbs',
  props: {
    list: {
      type: Array,
      default: () => [],
    }
  },
  methods: {
    getComponent(item, idx) {
      const isLast = idx + 1 === this.list.length;

      if (isLast) {
        return {
          is: 'span',
        };
      }

      return {
        is: 'BaseLinkWr',
        link: item.link,
      };
    },
  },
};
