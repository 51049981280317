//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageConstructor',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    pageName: {
      type: String,
      required: true,
    },
  },
  computed: {
    breadcrumbs() {
      return [
        {
          title: this.$t('homepage'),
          link: {
            linktype: 'story',
            cached_url: this.localePath('/home'),
          },
        },
        {
          title: this.blok.title || this.pageName,
          link: {
            linktype: 'story',
            cached_url: this.$route.path,
          },
        },
      ];
    },
  },
};
